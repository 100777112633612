<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Receive list -->
        <material-card
          :title="$t('sideBar.voucherTransferRequestList')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="requestTransferVoucherFromAgentHeaders"
            :items="requestTransferVoucherFromAgentList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in requestTransferVoucherFromAgentHeaders"
                :key="header.value + index"
                :style="header.value == 'status' ? 'white-space: nowrap;' : ''"
              >
                <span
                  v-if="header.value == 'status'"
                  :style="'color: #fff; padding: 2px 5px 2px 5px; border-radius: 5px;' + (item[header.value] == 1 ? 'background-color: #66bb6a;' : item[header.value] == 2 ? 'background-color: red;' : 'background-color: orange;')">{{ $t(getStatus(item[header.value])) }}</span>
                <span v-else-if="header.value == 'distributer'">{{ item[header.value].name }}</span>
                <span v-else-if="header.value == 'fromAgentLevel2'">{{ item[header.value].name }}</span>
                <span v-else-if="header.value == 'toAgentLevel2'">{{ item[header.value].name }}</span>
                <span v-else-if="header.value == 'userAction'">{{ item[header.value].full_name }}</span>
                <span v-else-if="header.value == 'workpiece'">{{ item[header.value].name }}</span>
                <div
                  v-else-if="header.value == 'actions'"
                  style="display: flex;">
                  <v-tooltip
                    v-if="item.status == requestTransferStatus.INITIAL"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isApproveLoading"
                        class="mx-2"
                        fab
                        small
                        color="primary"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalConfirm($event, item, requestTransferStatus.APPROVE)"
                      >
                        <v-icon dark>mdi-check-bold</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('issueVoucherRequest.approve') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="item.status == requestTransferStatus.INITIAL"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isCancelLoading"
                        class="mx-2"
                        fab
                        small
                        color="error"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalConfirm($event, item, requestTransferStatus.CANCEL)"
                      >
                        <v-icon dark>mdi-close-thick</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('issueVoucherRequest.cancel') }}</span>
                  </v-tooltip>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmEVoucherTransfer" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import RequestTransferStatus from 'enum/requestTransferStatus'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    ConfirmModal
  },
  data: () => ({
    requestTransferVoucherFromAgentHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'issueVoucherRequest.title',
        value: 'name',
        align: 'center'
      },
      {
        sortable: false,
        text: 'voucherTransferRequest.approvedAgent',
        value: 'distributer',
        align: 'center'
      },
      {
        sortable: false,
        text: 'voucherTransferRequest.transferAgent',
        value: 'fromAgentLevel2',
        align: 'center'
      },
      {
        sortable: false,
        text: 'voucherTransferRequest.receivingAgent',
        value: 'toAgentLevel2',
        align: 'center'
      },
      {
        sortable: false,
        text: 'voucherHistory.peopleAction',
        value: 'userAction',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.workpiece',
        value: 'workpiece',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.quantity',
        value: 'quantity',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.description',
        value: 'description',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.status',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    requestTransferVoucherFromAgentList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    requestTransferStatus: {
      APPROVE: RequestTransferStatus.APPROVE,
      CANCEL: RequestTransferStatus.CANCEL,
      INITIAL: RequestTransferStatus.INITIAL
    },
    confirmModalTitle: null,
    requestTransferItem: null,
    requestTransferStatusSelected: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'REQUEST_TRANSFER_FROM_AGENT_DATA'
    ])
  },
  watch: {
    REQUEST_TRANSFER_FROM_AGENT_DATA () {
      let res = this.REQUEST_TRANSFER_FROM_AGENT_DATA
      // Paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage
      // Handle data
      this.requestTransferVoucherFromAgentList = []
      let data = res.results
      for (let i = 0, size = data.length; i < size; i++) {
        let requestTransferVoucher = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          name: data[i].name,
          distributer: data[i].parent_entity,
          fromAgentLevel2: data[i].from_entity,
          toAgentLevel2: data[i].to_entity,
          userAction: data[i].user_action,
          workpiece: data[i].workpiece,
          status: data[i].status,
          quantity: data[i].quantity,
          description: data[i].description,
          isApproveLoading: false,
          isCancelLoading: false
        }
        this.requestTransferVoucherFromAgentList.push(requestTransferVoucher)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getRequestTransferVoucherFromAgent()
  },
  methods: {
    /**
     * Show modal confirm
     */
    onShowModalConfirm: function (event, item, transferStatus) {
      event.preventDefault()
      this.requestTransferItem = item
      this.requestTransferStatusSelected = transferStatus
      if (this.requestTransferStatusSelected === RequestTransferStatus.APPROVE) {
        this.confirmModalTitle = this.$t('issueVoucherRequest.approveTitle')
      } else if (this.requestTransferStatusSelected === RequestTransferStatus.CANCEL) {
        this.confirmModalTitle = this.$t('issueVoucherRequest.cancelTitle')
      }
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Confirm e voucher transfer
     */
    onConfirmEVoucherTransfer: function () {
      this.onApproveRequestTransferFromAgent(this.requestTransferItem, this.requestTransferStatusSelected)
    },
    /**
     * Get status text
     */
    getStatus: function (status) {
      if (status === RequestTransferStatus.INITIAL) {
        return 'issueVoucherRequest.initial'
      } else if (status === RequestTransferStatus.APPROVE) {
        return 'issueVoucherRequest.approved'
      } else {
        return 'issueVoucherRequest.canceled'
      }
    },
    /**
     * Get request transfer voucher from agent
     */
    getRequestTransferVoucherFromAgent: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_REQUEST_TRANSFER_FROM_AGENT(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Approve request from agent
     */
    onApproveRequestTransferFromAgent: function (requestTransferItem, requestTransferStatus) {
      let filter = {
        requestTransferId: requestTransferItem.id,
        fromEntity: requestTransferItem.fromAgentLevel2,
        toEntity: requestTransferItem.toAgentLevel2,
        parentEntity: requestTransferItem.distributer,
        numberOfTransfer: requestTransferItem.quantity,
        requestTransferStatus: requestTransferStatus,
        workpiece: requestTransferItem.workpiece
      }
      // eslint-disable-next-line eqeqeq
      if (requestTransferStatus == RequestTransferStatus.APPROVE) {
        requestTransferItem.isApproveLoading = true
      // eslint-disable-next-line eqeqeq
      } else if (requestTransferStatus == RequestTransferStatus.CANCEL) {
        requestTransferItem.isCancelLoading = true
      }
      this.APPROVE_CANCEL_REQUEST_TRANSFER(filter).then(
        function () {
          requestTransferItem.isApproveLoading = false
          requestTransferItem.isCancelLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getRequestTransferVoucherFromAgent()
        }.bind(this)
      ).catch(
        function (error) {
          requestTransferItem.isApproveLoading = false
          requestTransferItem.isCancelLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
    },
    ...mapActions([
      'GET_REQUEST_TRANSFER_FROM_AGENT',
      'APPROVE_CANCEL_REQUEST_TRANSFER',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>
<style lang="scss" scoped></style>
