var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('sideBar.voucherTransferRequestList'),"color":"green","flat":"","full-width":""}},[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.requestTransferVoucherFromAgentHeaders,"items":_vm.requestTransferVoucherFromAgentList,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.requestTransferVoucherFromAgentHeaders),function(header,index){return _c('td',{key:header.value + index,style:(header.value == 'status' ? 'white-space: nowrap;' : '')},[(header.value == 'status')?_c('span',{style:('color: #fff; padding: 2px 5px 2px 5px; border-radius: 5px;' + (item[header.value] == 1 ? 'background-color: #66bb6a;' : item[header.value] == 2 ? 'background-color: red;' : 'background-color: orange;'))},[_vm._v(_vm._s(_vm.$t(_vm.getStatus(item[header.value]))))]):(header.value == 'distributer')?_c('span',[_vm._v(_vm._s(item[header.value].name))]):(header.value == 'fromAgentLevel2')?_c('span',[_vm._v(_vm._s(item[header.value].name))]):(header.value == 'toAgentLevel2')?_c('span',[_vm._v(_vm._s(item[header.value].name))]):(header.value == 'userAction')?_c('span',[_vm._v(_vm._s(item[header.value].full_name))]):(header.value == 'workpiece')?_c('span',[_vm._v(_vm._s(item[header.value].name))]):(header.value == 'actions')?_c('div',{staticStyle:{"display":"flex"}},[(item.status == _vm.requestTransferStatus.INITIAL)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"loading":item.isApproveLoading,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalConfirm($event, item, _vm.requestTransferStatus.APPROVE)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check-bold")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issueVoucherRequest.approve')))])]):_vm._e(),(item.status == _vm.requestTransferStatus.INITIAL)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"loading":item.isCancelLoading,"fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.onShowModalConfirm($event, item, _vm.requestTransferStatus.CANCEL)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issueVoucherRequest.cancel')))])]):_vm._e()],1):_c('span',[_vm._v(_vm._s(item[header.value]))])])})}}])})],1),_c('pagination',{attrs:{"total":_vm.paginate.totalPage,"current-page":_vm.paginate.currentPage,"row-per-page":_vm.paginate.rowPerPage},on:{"onPageChange":_vm.onPageChange}})],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('loading-bar',{attrs:{"is-loading":_vm.isLoading}}),_c('confirmModal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onConfirmEVoucherTransfer}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }